<template>
  <aRow style="margin-top: -20px">
    <aCol :span="24">
      <input
        name="email"
        style="
          color: #fff;
          opacity: 0;
          pointer-events: none;
          height: 0;
          padding: 0;
          border: 0;
        "
        placeholder="Fake email fix"
      />

      <aInput
        class="travel-input"
        v-model="userPassword"
        type="password"
        placeholder="Insira sua senha"
      >
        <aIcon slot="prefix" type="lock" style="color: rgba(0, 0, 0, 0.25)" />
      </aInput>
    </aCol>

    <aCol class="a-center" :span="24">
      <aButton
        @click="onClickVerifyPassword"
        class="mt-30"
        type="primary"
        block
      >
        Confirmar
      </aButton>
    </aCol>
  </aRow>
</template>

<script>
export default {
  name: "PasswordModal",
  data() {
    return {
      userPassword: "",
    };
  },
  mounted() {},
  methods: {
    onClickVerifyPassword() {
      if (this.userPassword) {
        this.$http
          .post("/token", {
            email: this.$store.state.userData.email,
            password: this.userPassword,
            is_login_page: false,
          })
          .then(() => {
            this.openPasswordModal = false;
            this.disableUserData = false;
            this.$http.post("/log/create", {
              user_id: this.$store.state.userData.id,
              module_id: this.tempUser.id,
              module: "user",
              action: "request-update-data",
              description: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name} inseriu a senha para editar os dados do usuário ID ${this.tempUser.id}.`,
            });
          })
          .catch(({ response }) => {
            this.$message.error(response.data.message);
          });
      } else {
        this.$message.warning("Insira sua senha e o código de autorização.");
      }
    },
  },
};
</script>
